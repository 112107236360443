import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthClient, ResetPasswordDto } from 'src/app/lib/api/api.client';
import CustomValidators from 'src/app/lib/validator';
import { ResetPasswordForm } from 'src/app/organisms/auth/reset-password-form/reset-password-form.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup<ResetPasswordForm> = this.formBuilder.group({
    password: new FormControl<string>('', [
      Validators.required,
      CustomValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
        requiresDigit: true,
      }),
      CustomValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
        requiresUppercase: true,
      }),
      CustomValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
        requiresLowercase: true,
      }),
      CustomValidators.patternValidator(new RegExp('(?=.*[$@^!%*?&#])'), {
        requiresSpecialChars: true,
      }),
    ]),
    confirmPassword: new FormControl<string>('', [
      Validators.required
    ]),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authClient: AuthClient,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private snack: MatSnackBar
  ) {}

  onSubmit() {
    const dto = {
      token: this.route.snapshot.queryParams['token'],
      userId: this.route.snapshot.queryParams['userId'],
      password: this.resetPasswordForm.value.password,
      confirmPassword: this.resetPasswordForm.value.confirmPassword
    } as ResetPasswordDto;

    this.authClient
      .resetPassword(dto)
      .subscribe(() => {
        this.snack.open(this.translateService.instant('resetPassword.success'));
        this.router.navigate(['/']);
      });
  }
}
