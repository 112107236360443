<div class="layout-container">
    <div class="layout-box auth-box">
        <!-- Logo -->
        <div class="logo-container">
            <img src="assets/logo.svg">
        </div>

        <!-- Title -->
        <h1>{{ "register.register" | translate }}</h1>
        <div>
            {{ 'register.registeredHint' | translate }}
            <a routerLink="/auth/login">{{ "login.login" | translate }}</a>
        </div>

        <!-- Sign Up form -->
        <br>
        <br>
        <app-register-form [registerForm]="registerForm" (submitAction)="onSubmit()"></app-register-form>

    </div>
    <div class="layout-box marketing-box">
        <h1>{{ 'marketing.header' | translate }}</h1>
        <p>{{ 'marketing.subtitle' | translate }}</p>
    </div>
</div>