<div class="layout-container">
    <div class="layout-box auth-box">
        <!-- Logo -->
        <div class="logo-container">
            <img src="assets/logo.svg">
        </div>

        <div class="logo-spacer"></div>

        <!-- Title -->
        <h1>{{ 'forgotPassword.title' | translate }}</h1>
        <div>{{ 'forgotPassword.hint' | translate }}</div>

        <!-- Forgot password form -->
        <br>
        <br>
        <app-forgot-password-form [forgotPasswordForm]="forgotPasswordForm"
            (submitAction)="onSubmit()"></app-forgot-password-form>

        <!-- Footer -->
        <br>
        <br>
        <div>
            {{ 'form.returnTo' | translate }} <a routerLink="/auth/login">{{ 'login.login' | translate }}</a>
        </div>

    </div>
    <div class="layout-box marketing-box">
        <h1>{{ 'marketing.header' | translate }}</h1>
        <p>{{ 'marketing.subtitle' | translate }}</p>
    </div>
</div>
