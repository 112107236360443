<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'login.email' | translate }}</mat-label>
      <input
        matInput
        placeholder="email@example.com"
        formControlName="email"
        required
      />
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>{{ 'login.password' | translate }}</mat-label>
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        required
        formControlName="password"
      />
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword"
      >
        <mat-icon class="icon-size-5">{{
          hidePassword ? "visibility" : "visibility_off"
        }}</mat-icon>
      </button>
    </mat-form-field>

    <!-- Actions -->
    <div>
      <a routerLink="/auth/forgot-password">{{ 'forgotPassword.title' | translate  }}</a>
    </div>

    <br />
    <br />
    <button type="submit" mat-flat-button color="primary">
      {{ 'login.login' | translate }}
    </button>
  </div>
</form>

