import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import CustomValidators from 'src/app/lib/validator';
import { RegisterForm } from 'src/app/organisms/auth/register-form/register-form.component';
import { AuthClient, UserRegistrationDto } from 'src/app/lib/api/api.client';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent {
  registerForm: FormGroup<RegisterForm> = this.formBuilder.group(
    {
      firstName: new FormControl('', [
        Validators.required
      ]),
      lastName: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
          requiresDigit: true,
        }),
        CustomValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
          requiresUppercase: true,
        }),
        CustomValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
          requiresLowercase: true,
        }),
        CustomValidators.patternValidator(new RegExp('(?=.*[$@^!%*?&#])'), {
          requiresSpecialChars: true,
        }),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    {
      validators: [CustomValidators.match('password', 'confirmPassword')],
    }
  );

  constructor(
    private router: Router,
    private snack: MatSnackBar,
    protected formBuilder: FormBuilder,
    private translateService: TranslateService,
    protected authClient: AuthClient,
  ) {}

  onSubmit() {
    const dto = this.registerForm.value as UserRegistrationDto;
    dto.language = this.translateService.getDefaultLang();

    this.authClient
      .registerUser(dto)
      .subscribe(() => {
        this.snack.open(this.translateService.instant('register.success'));
        this.router.navigate(['/auth/login']);
      });
  }
}
