<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  <div class="container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.firstName' | translate }}</mat-label>
      <input matInput required formControlName="firstName" />
      <mat-error>{{checkForErrorsIn(firstName)}}</mat-error>
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.lastName' | translate }}</mat-label>
      <input matInput required formControlName="lastName" />
      <mat-error>{{checkForErrorsIn(lastName)}}</mat-error>
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.email' | translate }}</mat-label>
      <input
        matInput
        placeholder="test@example.com"
        required
        formControlName="email"
      />
      <mat-error>{{checkForErrorsIn(email)}}</mat-error>
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>{{ 'register.password' | translate }}</mat-label>
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        required
        formControlName="password"
        (change)="registerForm.controls.confirmPassword.updateValueAndValidity()"
      />
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword"
      >
        <mat-icon class="icon-size-5">{{
          hidePassword ? "visibility" : "visibility_off"
        }}</mat-icon>
      </button>
      <mat-error>{{checkForErrorsIn(password)}}</mat-error>
    </mat-form-field>
    <div class="break-lines">{{ 'register.passwordPolicy' | translate }}</div>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>{{ 'register.confirmPassword' | translate }}</mat-label>
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        required
        formControlName="confirmPassword"
      />
      <mat-error>{{checkForErrorsIn(confirmPassword)}}</mat-error>
    </mat-form-field>
    <br />
    <button type="submit" mat-flat-button color="primary">
      {{ 'register.register' | translate }}
    </button>
  </div>
</form>

