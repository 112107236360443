<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div class="container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'register.password' | translate }}</mat-label>
      <input
        id="create-password"
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        required
        formControlName="password"
        (change)="resetPasswordForm.controls.confirmPassword.updateValueAndValidity()"
      />
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword"
      >
        <mat-icon>{{
          hidePassword ? "visibility" : "visibility_off"
        }}</mat-icon>
      </button>
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>{{ 'register.confirmPassword' | translate }}</mat-label>
      <input
        id="confirm-password"
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        required
        formControlName="confirmPassword"
      />
    </mat-form-field>
    <br />
    <button type="submit" mat-flat-button color="primary">
      {{ 'form.submit' | translate }}
    </button>
  </div>
</form>
