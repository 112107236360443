import { Injectable } from '@angular/core';
import { SettingsStorageService } from './settings-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {

  constructor(private settingStorageService: SettingsStorageService) {}

  logout(): void {
    this.clear();
  }

  clear(): void {
    const language = this.settingStorageService.getLanguage();
    localStorage.clear();
    sessionStorage.clear();
    this.settingStorageService.setLanguage(language);
  }

  isLoggedIn(): boolean {
    return this.getToken() !== null;
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  setRole(role: string): void {
    localStorage.setItem('role', role);
  }

  setName(name: string): void {
    localStorage.setItem('name', name);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getRole(): string | null {
    return localStorage.getItem('role');
  }

  getName(): string | null {
    return localStorage.getItem('name');
  }
}
