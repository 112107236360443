import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface ForgotPasswordForm {
  email: FormControl<string | null>;
}

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent {
  @Input() forgotPasswordForm!: FormGroup<ForgotPasswordForm>;
  @Output() submitAction: EventEmitter<any> = new EventEmitter();

  onSubmit() {
    if (this.forgotPasswordForm.valid) {
      this.submitAction.emit();
    }
  }
}
