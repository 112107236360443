import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthPageRoutingModule } from './auth-routing.module';
import { RegisterFormModule } from 'src/app/organisms/auth/register-form/register-form.module';
import { AuthClient } from 'src/app/lib/api/api.client';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterPageComponent } from './register-page/register-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginFormModule } from 'src/app/organisms/auth/login-form/login-form.module';
import { AuthComponent } from './auth.component';
import { ForgotPasswordFormModule } from 'src/app/organisms/auth/forgot-password-form/forgot-password-form.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordFormModule } from 'src/app/organisms/auth/reset-password-form/reset-password-form.module';

@NgModule({
  declarations: [
    AuthComponent, 
    LoginPageComponent, 
    RegisterPageComponent, 
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  providers: [AuthClient],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    AuthPageRoutingModule,
    LoginFormModule,
    RegisterFormModule,
    ForgotPasswordFormModule,
    ResetPasswordFormModule
  ],
})
export class AuthModule {}
