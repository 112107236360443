import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthStorageService } from '../service/auth-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  protected ignoreUrls = ['auth/login', 'auth/register', 'assets'];

  protected authReq!: HttpRequest<any>;

  constructor(
    private authStorageService: AuthStorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldIgnoreUrl(req.url)) {
      return next.handle(req);
    }

    if (!this.authStorageService.isLoggedIn()) {
      return next.handle(req);
    }

    const authHeader = 'Bearer ' + this.authStorageService.getToken();
    this.authReq = req.clone({
      headers: req.headers.set('Authorization', authHeader),
    });

    return next.handle(this.authReq);
  }

  protected shouldIgnoreUrl(url: string): boolean {
    return !!this.ignoreUrls.find((ignore) => url.indexOf(ignore) !== -1);
  }
}
