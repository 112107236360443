import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface ResetPasswordForm {
  password: FormControl<string | null>,
  confirmPassword: FormControl<string | null>
}

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent {
  hidePassword: boolean = true;
  @Input() resetPasswordForm!: FormGroup<ResetPasswordForm>;
  @Output() submitAction: EventEmitter<any> = new EventEmitter();

  onSubmit() {
    if (this.resetPasswordForm.valid) {
      this.submitAction.emit();
    }
  }
}
