<div class="layout-container">
    <div class="layout-box auth-box">
        <!-- Logo -->
        <div class="logo-container">
            <img src="assets/logo.svg">
        </div>

        <div class="logo-spacer"></div>

        <!-- Title -->
        <h1>{{ 'login.login' | translate}}</h1>
        <div>
            {{ 'login.registerHint' | translate }}
            <a routerLink="/auth/register">{{ 'register.register' | translate }}</a>
        </div>

        <!-- Sign in form -->
        <br>
        <br>
        <app-login-form [loginForm]="loginForm" (submitAction)="onSubmit()"></app-login-form>
    </div>

    <div class="layout-box marketing-box">
        <h1>{{ 'marketing.header' | translate }}</h1>
        <p>{{ 'marketing.subtitle' | translate }}</p>
    </div>
</div>