import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export interface RegisterForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent {
  hidePassword: boolean = true;
  @Input() registerForm!: FormGroup<RegisterForm>;
  @Output() submitAction: EventEmitter<any> = new EventEmitter();

  get firstName():AbstractControl | null { return this.registerForm.get('firstName'); }
  get lastName():AbstractControl | null { return this.registerForm.get('lastName'); }
  get email():AbstractControl | null { return this.registerForm.get('email'); }
  get password():AbstractControl | null { return this.registerForm.get('password'); }
  get confirmPassword():AbstractControl | null { return this.registerForm.get('confirmPassword'); }

  constructor(private translate: TranslateService) {}

  checkForErrorsIn(formControl: AbstractControl | null): string {
    if (formControl?.hasError('required')) {
      return this.translate.instant('form.error.required');
    }

    if (formControl?.hasError('email')) {
      return this.translate.instant('form.error.email');
    }

    if (formControl?.hasError('requiresDigit')) {
      return this.translate.instant('form.error.requiresDigit');
    }    
    
    if (formControl?.hasError('requiresUppercase')) {
      return this.translate.instant('form.error.requiresUppercase');
    }    
    
    if (formControl?.hasError('requiresLowercase')) {
      return this.translate.instant('form.error.requiresLowercase');
    }    
    
    if (formControl?.hasError('requiresSpecialChars')) {
      return this.translate.instant('form.error.requiresSpecialChars');
    }    
    
    if (formControl?.hasError('matching')) {
      return this.translate.instant('form.error.matching');
    }

    return ''
  }

  onSubmit() {
    if (this.registerForm.valid) {
      this.submitAction.emit();
    }
  }
}
