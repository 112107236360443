import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthClient } from 'src/app/lib/api/api.client';

export interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  hidePassword: boolean = true;
  @Input() loginForm!: FormGroup<LoginForm>;
  @Output() submitAction: EventEmitter<any> = new EventEmitter();

  constructor(
    protected formBuilder: FormBuilder,
    protected authClient: AuthClient,
  ) {}

  onSubmit() {
    if (this.loginForm.valid) {
      this.submitAction.emit();
    }
  }
}
