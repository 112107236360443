import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  constructor ( 
    private snack: MatSnackBar,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  public handleError(err: HttpErrorResponse): void {

    switch (err.status) {
      case 0:
        this.snack.open(this.translateService.instant('error.0'));
        return;
      case 401:
        this.snack.open(this.translateService.instant('error.401'));
        this.authService.logout();
        return;
      case 500:
        this.snack.open(this.translateService.instant('error.500'));
        return;
      default:
        if (err.error.errors) {
          this.snack.open(err.error.errors[0]);
        } else  if (err.error.errorMessage) {
          this.snack.open(err.error.errorMessage);
        } else if (err.statusText) {
          this.snack.open(err.statusText);
        } else {
          this.snack.open('Unknown');
        }
    }
  }
}
