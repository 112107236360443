import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthClient, ForgotPasswordDto } from 'src/app/lib/api/api.client';
import { ForgotPasswordForm } from 'src/app/organisms/auth/forgot-password-form/forgot-password-form.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup<ForgotPasswordForm> = this.formBuilder.group({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
    ]),
  });

  constructor(
    private router: Router,
    private authClient: AuthClient,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private snack: MatSnackBar
  ) {}

  onSubmit() {
    const dto = {
      email: this.forgotPasswordForm.value.email
    } as ForgotPasswordDto;

    this.authClient
      .forgotPassword(dto)
      .subscribe(() => {
        this.snack.open(this.translateService.instant('forgotPassword.success'));
        this.router.navigate(['/']);
      });
  }
}
