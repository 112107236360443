<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <div class="container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'login.email' | translate }}</mat-label>
      <input
        matInput
        placeholder="email@example.com"
        formControlName="email"
        required
      />
    </mat-form-field>
    <br />
    <button type="submit" mat-flat-button color="primary">
      {{ 'form.submit' | translate }}
    </button>
  </div>
</form>